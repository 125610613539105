import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { FormDuufField, FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import useGeneralInfo from '~/hooks/useGeneralInfo'
import Marker from '~/components/elements/svg/Marker'
import { LosseLink, useLosseLanguage } from '@ubo/losse-sjedel'
import Phone from '~/components/elements/svg/Phone'
import Envelope from '~/components/elements/svg/Envelope'
import Facebook from '~/components/elements/svg/Facebook'
import Linkedin from '~/components/elements/svg/Linkedin'
import Instagram from '~/components/elements/svg/Instagram'
import Youtube from '~/components/elements/svg/Youtube'
import X from '~/components/elements/svg/X'
import A from '~/components/elements/svg/A'
import clsx from 'clsx'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { general, socials } = useGeneralInfo()
  const [, locale] = useLosseLanguage()

  return (
    <section data-component="FormContact" className="section bg-site-light relative pb-36 lg:!pt-40">
      <A className="absolute top-14 lg:top-28 -left-12 opacity-50 max-w-full h-auto" />

      <div className="container md:pt-20 pt-16 md:grid flex flex-col grid-cols-12 relative xl:gap-0 md:gap-20">
        <div className="mb-6 col-span-4">
          <Content
            className={clsx(
              'content md:mb-20 mb-6',
              fields?.title?.length <= 5
                ? 'xl:children-h1:text-7xl lg:children-h1:text-6xl md:children-h1:text-5xl'
                : 'xl:children-h1:text-6xl lg:children-h1:text-5xl md:children-h1:text-4xl'
            )}
          >
            <h1>{fields?.title}</h1>
          </Content>

          <div className="lg:pt-14">
            <ul className="[&_li>a]:flex [&_li>a]:items-center [&_li]:mb-2 mb-8">
              <li>
                <LosseLink to={general.address.url} target="_blank" className="hover:underline">
                  <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                    <Marker />
                  </div>
                  <Content className="content">
                    <p>{general.address.title}</p>
                  </Content>
                </LosseLink>
              </li>
              <li>
                <LosseLink to={`mailto:${general.email}`} className="hover:underline">
                  <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                    <Envelope />
                  </div>
                  <Content className="content">
                    <p>{general.email}</p>
                  </Content>{' '}
                </LosseLink>
              </li>
              <li>
                <LosseLink to={`tel:${general.phone}`} className="hover:underline">
                  <div className="rounded-lg border-2 border-site-base-200 p-2 mr-3">
                    <Phone />
                  </div>
                  <Content className="content">
                    <p>{general.phone}</p>
                  </Content>
                </LosseLink>
              </li>
            </ul>

            <Content className="content mb-6 lg:mt-14">
              <h2>{locale === 'nl_NL' ? 'Volg ons' : 'Follow us'}</h2>
            </Content>

            <div className="flex flex-wrap gap-4">
              {[
                {
                  icon: Facebook,
                  value: socials.facebook
                },
                {
                  icon: Linkedin,
                  value: `${socials.linkedin}${locale === 'en_US' ? '?locale=en_US' : ''}`
                },
                {
                  icon: Instagram,
                  value: socials.instagram
                },
                {
                  icon: Youtube,
                  value: socials.youtube
                },
                {
                  icon: X,
                  value: socials.twitter
                }
              ].map(({ icon: Icon, value }, key) => (
                <LosseLink className="bg-white p-3 rounded-lg group" rel="noreferrer noopener" target="_blank" to={value} key={key}>
                  <Icon className="w-5 h-5 group-hover:scale-125 transition-transform" />
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-8">
          <Content className="content mb-10">{fields?.description}</Content>
          <div className="mt-5 bg-gradient xl:-ml-10 text-white md:p-10 sm:p-8 p-6 rounded-[25px] lg:py-14 lg:px-16 relative overflow-hidden">
            <Form
              className="form-contact"
              data={fields.form}
              renderLoading={() => <Loading />}
              generate={false}
              renderStatus={({ content }) => <Content className="content">{content}</Content>}
            >
              <div className="lg:grid flex flex-col grid-cols-2 lg:gap-10 gap-4">
                <div className="">
                  <FormDuufGroup hideIds={[3, 8]} />
                </div>

                <div>
                  <FormDuufGroup showIds={[3]} />
                </div>

                <div className="col-span-2 flex lg:flex-row flex-col justify-between items-center gap-8">
                  <FormDuufField id={8} />
                  <FormDuufSubmit />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}
